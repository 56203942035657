import React from "react";
import AuthenticationLayout from "../components/AuthenticationLayout";
import ClosedContainer from "../components/FormBuilder/ClosedContainer/ClosedContainer";
import "../styles/form.scss";

const Closed = () => (
  <AuthenticationLayout>
    <ClosedContainer />
  </AuthenticationLayout>
);

export default Closed;
