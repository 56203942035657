import { FieldTypes } from "../../../utils/fieldTypes";

const ClosedDefaultFields = () => {
  const fields = [
    {
      label: `Based on the agent(s) provided, how did their service compare to your expectations?`,
      keyName: `based_on_the_agent_s__provided__how_did_their_service_compare_to_your_expectations___closed_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `What went well with your agent? What went poorly with your agent?`,
      keyName: `what_went_well_with_your_agent__what_went_poorly_with_your_agent_`,
      type: FieldTypes.textarea,
    },
    {
      label: `If you had to pay 6%, would you still have picked the same agent?`,
      keyName: `if_you_had_to_pay_6___would_you_still_have_picked_the_same_agent_`,
      type: FieldTypes.radioNumber,
      options: ["Yes", "No"],
    },
    {
      label: `If you had to pay 6% total, why would you have chosen a different agent?`,
      keyName: `if_you_had_to_pay_6__total__why_would_you_have_chosen_a_different_agent_`,
      type: FieldTypes.textarea,
      triggers: {
        if_you_had_to_pay_6___would_you_still_have_picked_the_same_agent_: "No",
      },
    },
    {
      label: `How would you rate Clever's communication after you were matched with your agent(s)?`,
      keyName: `how_would_you_rate_clever_s_communication_after_you_were_matched_with_your_agent_s____closed_`,
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `Regarding Clever's Communication – What could have been better?`,
      keyName: `regarding_clever_s_communication___what_could_have_been_better____closed_`,
      type: FieldTypes.textarea,
      triggers: {
        how_would_you_rate_clever_s_communication_after_you_were_matched_with_your_agent_s____closed_:
          ["1", "2", "3", "4"],
      },
    },
    {
      label: "How helpful was our concierge team?",
      keyName: "how_helpful_was_our_concierge_team___closed_",
      type: FieldTypes.radioNumber,
      options: ["1", "2", "3", "4", "5"],
    },
    {
      label: `What could we have done better?`,
      keyName: `what_could_we_have_done_better___closed_`,
      type: FieldTypes.textarea,
      triggers: {
        how_helpful_was_our_concierge_team___closed_: ["1", "2", "3", "4"],
      },
    },
  ];

  return fields;
};

const ClosedGeneralFields = () => [
  {
    type: FieldTypes.header,
    keyName: "generalHeader",
    label: `General Questions`,
  },
  {
    label:
      "Based on your experience so far, how likely are you to refer a friend to Clever?",
    keyName: "how_likely_are_you_to_refer_a_friend_to_clever___closed_",
    type: FieldTypes.radioNumber,
    required: true,
    options: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10"],
  },
  {
    label: `What were you looking for that Clever wasn't able to provide?`,
    keyName:
      "what_were_you_looking_for_that_clever_wasn_t_able_to_provide___closed_",
    type: FieldTypes.textarea,
    triggers: {
      how_likely_are_you_to_refer_a_friend_to_clever___closed_: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
      ],
    },
  },
];

const getFields = () => {
  let fields = [] as any[];
  const agentFields = ClosedDefaultFields();
  const generalFields = ClosedGeneralFields();
  fields = [agentFields, generalFields].flat();
  return fields;
};

export { getFields };
